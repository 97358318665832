<template>
  <div>
    <v-tooltip top color="primary">
      <template #activator="{ on }">
        <v-btn icon v-on="on" @click="newEntry()">
          <v-icon color="primary">
            fal fa-plus-circle
          </v-icon>
        </v-btn>
      </template>
      <span>Add Message Board Item</span>
    </v-tooltip>

    <!--Entry-->
    <v-dialog v-model="show" scrollable persistent max-width="700">
      <v-card flat class="white">
        <v-card-title class="text-h5 secondary--text"
          >Message Board Item Details<v-spacer /><v-btn text rounded outlined @click="cancelEntry()"
            ><v-icon small left>fal fa-times</v-icon>Cancel</v-btn
          ></v-card-title
        >
        <v-card-text>
          <v-form ref="entryForm" v-model="valid" @submit.prevent="saveEntry()" lazy-validation>
            <v-container fluid>
              <v-row dense>
                <!--Start Date-->
                <v-col cols="12" sm="12" md="6">
                  <v-menu
                    :close-on-content-click="false"
                    v-model="menu"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template #activator="{ on }">
                      <v-text-field
                        v-on="on"
                        v-model="d_dateStart"
                        label="Start Date"
                        placeholder=" "
                        persistent-placeholder
                        prepend-icon="fal fa-calendar-alt"
                        required
                        @blur="date1 = $_parseDate(d_dateStart)"
                      />
                    </template>
                    <v-date-picker
                      v-model="date1"
                      @input="menu = false"
                      color="primary"
                    ></v-date-picker>
                  </v-menu>
                </v-col>

                <!--End Date-->
                <v-col cols="12" sm="12" md="6">
                  <v-menu
                    :close-on-content-click="false"
                    v-model="menu2"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template #activator="{ on }">
                      <v-text-field
                        v-on="on"
                        v-model="d_dateEnd"
                        label="End Date"
                        placeholder=" "
                        persistent-placeholder
                        prepend-icon="fal fa-calendar-alt"
                        required
                        @blur="date2 = $_parseDate(d_dateEnd)"
                      />
                    </template>
                    <v-date-picker
                      v-model="date2"
                      @input="menu2 = false"
                      color="primary"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>

              <v-row dense>
                <!--Subject-->
                <v-col cols="12" sm="12">
                  <v-text-field
                    v-model="d_subject"
                    label="Subject"
                    placeholder=" "
                    persistent-placeholder
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <!--Body-->
                <v-col cols="12" sm="12">
                  <v-textarea
                    rows="6"
                    v-model="d_body"
                    label="Message Body (HTML)"
                    placeholder=" "
                    persistent-placeholder
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <!--Sort-->
                <v-col cols="12" sm="12" md="4">
                  <v-text-field
                    v-model="d_sort"
                    label="Sort"
                    placeholder=" "
                    persistent-placeholder
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <!--Always Show-->
                <v-col cols="12" sm="12" md="4">
                  <v-autocomplete
                    :items="valueListsStore.yesNo"
                    label="Always Show"
                    placeholder=" "
                    persistent-placeholder
                    item-text="value"
                    item-value="id"
                    v-model="f_alwaysShow"
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <!--Status-->
                <v-col cols="12" sm="12" md="4">
                  <v-autocomplete
                    :items="valueListsStore.activeStatus"
                    label="Status"
                    placeholder=" "
                    persistent-placeholder
                    item-text="value"
                    item-value="id"
                    v-model="f_status"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn block rounded color="primary" @click="saveEntry()"
            ><v-icon small left>fal fa-check</v-icon>Submit</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { createHelpers } from "vuex-map-fields";
import { mixin } from "@/mixins/mixin.js";

const { mapFields } = createHelpers({
  getterType: "messageBoardItemsStore/getEntryField",
  mutationType: "messageBoardItemsStore/updateEntryField"
});

export default {
  name: "MessageBoardItemEntry",
  mixins: [mixin],
  computed: {
    ...mapState({
      messageBoardItemsStore: state => state.messageBoardItemsStore,
      valueListsStore: state => state.valueListsStore
    }),
    ...mapFields([
      "show",
      "valid",
      "mode",
      "id",

      "d_dateStart",
      "date1",
      "d_dateEnd",
      "date2",
      "d_subject",
      "d_body",
      "d_sort",
      "f_alwaysShow",
      "f_status",
      "f_show"
    ])
  },
  data() {
    return {
      menu: false,
      menu2: false
    };
  },
  watch: {
    date1() {
      this.d_dateStart = this.$_formatDate(this.date1);
    },
    date2() {
      this.d_dateEnd = this.$_formatDate(this.date2);
    }
  },
  methods: {
    async newEntry() {
      this.valid = true;
      this.mode = 1;
      this.id = "";

      this.d_dateStart = "";
      this.date1 = "";
      this.d_dateEnd = "";
      this.date2 = "";
      this.d_subject = "";
      this.d_body = "";
      this.d_sort = "";
      this.f_alwaysShow = "";
      this.f_status = 1;
      this.f_show = 1;
      this.show = true;
    },

    cancelEntry() {
      this.show = false;
    },

    async saveEntry() {
      if (this.$refs.entryForm.validate()) {
        const entry = this.$store.getters["messageBoardItemsStore/getEntry"];
        /* Create */
        if (this.mode === 1) {
          await this.$store.dispatch("messageBoardItemsStore/create", entry);
        } else {
        /* Edit */
          await this.$store.dispatch("messageBoardItemsStore/update", entry);
        }
        this.cancelEntry();
      }
    }
  }
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
button {
  outline: none;
}
</style>
