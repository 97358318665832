import { render, staticRenderFns } from "./MessageBoardItemEntry.vue?vue&type=template&id=bcf3b07e&scoped=true&"
import script from "./MessageBoardItemEntry.vue?vue&type=script&lang=js&"
export * from "./MessageBoardItemEntry.vue?vue&type=script&lang=js&"
import style0 from "./MessageBoardItemEntry.vue?vue&type=style&index=0&id=bcf3b07e&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bcf3b07e",
  null
  
)

export default component.exports