<template>
  <div>
    <!--Title Bar-->
    <title-bar :title-value="'Message Board Items'" />

    <!-- Filter -->
    <v-toolbar flat dense>
      <!--  Filter Icon -->
      <v-menu v-model="filterMenu" offset-y right :close-on-content-click="false">
        <template #activator="{ on }">
          <v-btn rounded color="primary" v-on="on"
            ><v-icon small left>fal fa-filter</v-icon><span v-if="showFiltered()">Filtered</span
            ><span v-else>Filter</span>
          </v-btn>
        </template>

        <v-card width="400px">
          <v-card-text>
            <v-container fluid>
              <v-row dense>
                <v-col cols="12" sm="12" v-if="showFiltered()">
                  <v-btn block rounded color="primary" @click="clearFilter()">
                    <v-icon small left>fal fa-times</v-icon>Clear Filters
                  </v-btn>
                </v-col>

                <!--Start Date-->
                <v-col cols="12" sm="12">
                  <v-menu
                    :close-on-content-click="false"
                    v-model="menu"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template #activator="{ on }">
                      <v-text-field
                        v-on="on"
                        v-model="d_dateStart"
                        label="Start Date"
                        placeholder=" "
                        persistent-placeholder
                        prepend-icon="fal fa-calendar-alt"
                        required
                        @blur="date1 = $_parseDate(d_dateStart)"
                      />
                    </template>
                    <v-date-picker
                      v-model="date1"
                      @input="menu = false"
                      color="primary"
                    ></v-date-picker>
                  </v-menu>
                </v-col>

                <!--Status-->
                <v-col cols="12" sm="12">
                  <v-autocomplete
                    :items="valueListsStore.activeStatus"
                    label="Status"
                    placeholder=" "
                    persistent-placeholder
                    item-text="value"
                    item-value="id"
                    v-model="f_status"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn block rounded color="primary" @click="filter()">
              <v-icon small left>fal fa-check</v-icon>Submit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>

      <v-spacer />

      <!--Entry Dialog-->
      <message-board-item-entry />
    </v-toolbar>

    <!--Pagination-->
    <pagination
      :show="
        messageBoardItemsStore.messageBoardItems.data &&
          messageBoardItemsStore.messageBoardItems.data.length > 0
      "
      :showTotal="true"
      :currentPage="messageBoardItemsStore.messageBoardItems.current_page"
      :lastPage="messageBoardItemsStore.messageBoardItems.last_page"
      :total="messageBoardItemsStore.messageBoardItems.total"
      v-on:paginate-event="filter($event)"
    />

    <div v-if="progressStore.progress !== true">
      <!--List-->
      <list-basic
        :show="
          messageBoardItemsStore.messageBoardItems.data &&
            messageBoardItemsStore.messageBoardItems.data.length > 0
        "
        :list-data="messageBoardItemsStore.messageBoardItems.data"
        :title="'Message Board Items'"
        :section="41"
        @open-entry="openEntry($event)"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { createHelpers } from "vuex-map-fields";
import { mixin } from "@/mixins/mixin.js";
import TitleBar from "@/components/TitleBar";
import MessageBoardItemEntry from "@/components/MessageBoardItemEntry";
import Pagination from "@/components/Pagination";
import ListBasic from "@/components/ListBasic";

const { mapFields } = createHelpers({
  getterType: "messageBoardItemsStore/getFilterField",
  mutationType: "messageBoardItemsStore/updateFilterField"
});

export default {
  name: "SettingsMessageBoardItems",
  components: {
    TitleBar,
    MessageBoardItemEntry,
    Pagination,
    ListBasic
  },
  mixins: [mixin],
  computed: {
    ...mapState({
      messageBoardItemsStore: state => state.messageBoardItemsStore,
      valueListsStore: state => state.valueListsStore,
      progressStore: state => state.progressStore
    }),
    ...mapFields(["page", "section", "d_dateStart", "date1", "f_status", "dateCurrent"])
  },
  props: {
    /* All List = 1, Dashboard = 2, Settings = 3 */
    messageBoardSection: {
      type: Number,
      required: false
    }
  },
  data() {
    return {
      filterMenu: false,
      menu: false
    };
  },
  watch: {
    date1() {
      this.d_dateStart = this.$_formatDate(this.date1);
    }
  },
  props: {
    /* All List = 1, Provider = 2, Client = 3, Dashboard = 4 */
    taskSection: {
      type: Number,
      required: false
    }
  },
  created() {
    this.initialize();
  },

  methods: {
    async initialize() {
      this.$store.dispatch("progressStore/set", true);

      await this.changeSectionCheck();

      /* Grab filters */
      const filters = this.$store.getters["messageBoardItemsStore/getFilters"];
      /* Store authorized user in store */
      await this.$store.dispatch("messageBoardItemsStore/fetch", filters);
      this.$store.dispatch("progressStore/set", false);
    },

    async filter(page) {
      this.filterMenu = false;
      this.page = page;
      this.initialize();
    },

    clearFilterColumns() {
      this.page = 1;
      this.d_dateStart = "";
      this.f_status = "";
      this.dateCurrent = "";
    },

    async clearFilter() {
      this.filterMenu = false;
      this.clearFilterColumns();
      this.initialize();
    },

    async changeSectionCheck() {
      if (this.messageBoardSection !== this.section) {
        this.clearFilterColumns();
        this.$store.dispatch("messageBoardItemsStore/clearMessageBoardItems");
      }
      /* Save section in store */
      this.section = this.messageBoardSection;
    },

    showFiltered() {
      if (this.d_dateStart || this.f_status || this.f_status === 0) {
        return true;
      }
      return false;
    },

    async openEntry(id) {
      await this.$store.dispatch("messageBoardItemsStore/entry", id);
    }
  }
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
button {
  outline: none;
}
</style>
